import React, { useEffect, useState } from "react";
import axiosConfig from '../../axiosConfig';
import { Select } from "../../components/Core";
import imgH from "../../assets/image/l1/png/hero-image-man.png";
import imgP from "../../assets/image/patterns/hero-pattern.png";

const defaultCountries = [
  { value: "sp", label: "Singapore" },
  { value: "bd", label: "Bangladesh" },
  { value: "usa", label: "United States of America" },
  { value: "uae", label: "United Arab Emirates" },
  { value: "pk", label: "Pakistan" },
];

const Hero = () => {
  const [sliderData, setSliderData] = useState({ title: "", image: "" });
  useEffect(() => {
 //   axios.get('http://localhost:8000/api/slider')
	axiosConfig.get('/slider')
      .then(response => {
        if (response.data.success) {
			console.log(response.data.data);


          setSliderData(response.data.data);
          
        } else {
          console.error('Failed to fetch hero text');
        }
      })
      .catch(error => {
        console.error('Error fetching hero text:', error);
      });
  }, []);
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    // Fetch the locations data from the API
    axiosConfig.get('/locations-list')
      .then(response => {
        if (response.data.success) {
			console.log(response.data.data);
			   const formattedCountries = response.data.data.map(location => ({
          value: location.id,    // Assuming 'id' is the unique identifier
          label: location.title  // Assuming 'title' is the name to display
         }));

          setCountries(formattedCountries);
          
        } else {
          console.error('Failed to fetch locations');
        }
      })
      .catch(error => {
        console.error('Error fetching locations:', error);
      });
  }, []);

  return (
    <>
      {/* <!-- Hero Area --> */}
      <div className="bg-gradient-1 pt-26 pt-md-32 pt-lg-33 pt-xl-35 position-relative z-index-1 overflow-hidden">
        {/* <!-- .Hero pattern --> */}
        <div className="pos-abs-tr w-50 z-index-n2">
          <img src={imgP} alt="" className="gr-opacity-1" />
        </div>
        {/* <!-- ./Hero pattern --> */}
        <div className="container">
          <div className="row position-relative align-items-center">
            <div
              className="col-xxl-6 col-xl-7 col-lg-8 col-md-12 pt-lg-13 pb-lg-33 pb-xl-34 pb-md-33 pb-10"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="500"
            >
              <h1 className="font-size-11 mb-12 pr-md-30 pr-lg-0">
              {sliderData.title}
              </h1>
            
            </div>
            {/* <!-- Hero Right Image --> */}
            <div
              className="col-lg-6 col-md-4 col-sm-6 col-xs-6 col-8 pos-abs-br z-index-n1 position-static position-md-absolute mx-auto ml-md-auto"
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-delay="500"
            >
              <div className=" ml-xxl-23 ml-xl-12 ml-md-7">
              {sliderData.image && (
                  <img src={sliderData.image} alt="Hero" className="w-100" />
              )} </div>
            </div>
            {/* <!-- ./Hero Right Image --> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
